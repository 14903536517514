<template>
  <div class="fondo-student">
      <!-- <v-row>
          <v-card elevation="2" class="pa-10" @click="OpenSe">
              <v-card-title>Open App</v-card-title>
          </v-card>
      </v-row>
      <v-row class="ma-10">
        <a :href="'leelee://game?token='+ token">Test</a>
      </v-row> -->
      <div class="text-center mt-5 mb-10">
        <v-progress-circular
          :size="70"
          :width="7"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    
    <v-card max-width="500" class="mx-auto"  v-if="ShowAlert">
      <v-btn :href="OpenAppSe" :disabled="loadButtonRedirect" :loading="loadButtonRedirect" block link> Click here to start</v-btn>
    </v-card>
    <v-alert border="top" colored-border type="info" text max-width="500" class="mx-auto mt-6" v-if="ShowAlert">
      Make sure Lee Lee SE is completely installed.
    </v-alert>
  </div>
</template>

<script>
import { functions, auth ,db } from '../../../plugins/firebase'
import firebase from 'firebase'
export default {
	name: "ActivitiesList",
  data() {
      return {
        needDownload: false,
        user: '',
        inferior: false,
        superior: false,
        dialogDownload : false,
        token: '',
        boton: false,
        OpenAppSe: '',
        loadButtonRedirect:true,
        ShowAlert: false
      }
    },
    created () {
      console.log(window.navigator.userAgent)
      var agente = window.navigator.userAgent;
      var navegadores = ["Chrome", "Firefox", "Safari", "Opera", "Trident", "MSIE", "Edge"];
      for(var i in navegadores){
        if(agente.indexOf( navegadores[i]) != -1 ){
          //return navegadores[i];
          console.log(navegadores[i])
          console.log(navigator.userAgent.search("Safari"))
          console.log(navigator.userAgent.search("Chrome"))
        }
      }  
      //console.log(userExists)
      // if (userExists == null) {
      //   this.logout();
      // }
    },
    methods: {
      searchNavigator () {
        var agente = window.navigator.userAgent;
        var navegadores = ["Chrome", "Firefox", "Safari", "Opera", "Trident", "MSIE", "Edge"];
        for(var i in navegadores){
          if(agente.indexOf( navegadores[i]) != -1 ){
            return navegadores[i];
            // console.log(navegadores[i])
            // console.log(navigator.userAgent.search("Safari"))
            // console.log(navigator.userAgent.search("Chrome"))
          }
        }  
      },
      async logout () {
        await auth().signOut()
        localStorage.removeItem('email');
        localStorage.removeItem('uid');
        localStorage.removeItem('role');
        localStorage.removeItem('token');
        location.href = "/login";
      },
      async OpenSe () {
        let data = await db.collection("TempTokensClever").doc(this.$store.state.user.uid).get();
        let token =  data.data().token
        console.log(token)
        window.location.href = "leelee://game?token="+token;
      },
      async OpenTe () {
        let iud = this.$store.state.user.uid;
        let estado = this;
        //creamos su tabla
        await db.collection("SchoolTeacher").doc(iud).update({ openTE: "process" })
        //recuperamos el token
        const token = await auth().currentUser.getIdToken();
        console.log(token);
        console.log(auth().currentUser);
        setTimeout(function(){ 
          db.collection("SchoolTeacher").doc(iud).get().then((doc) => {
            console.log(doc.data().openTE)
            let openTE = doc.data().openTE;
            if(openTE == "process"){
              estado.needDownload = true;
            }
          });
          console.log('futuro') 
        }, 11000);
        console.log('presente')
        window.location.href = "leelee://game?token="+token;
      },
      VerifyIos(){
        //let userAgent = window.navigator.userAgent;
        //return (/iPad|iPhone|iPod/.test(userAgent));
        let userAgent = window.navigator.platform;
        return [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod'
        ].includes(window.navigator.platform)
        // iPad on iOS 13 detection
        || (window.navigator.userAgent.includes("Mac") && "ontouchend" in document)
      },
      async verifyUser (){
        const userExists = await auth().currentUser;
        console.log(userExists)
      },
      async verifyVersion(){
        let consultaVersion = await db.collection("Versions").doc("seweb").get();
        let version = consultaVersion.data().version;
        // consultar al local storage por si tiene una version guardadda
        var lastVersion = localStorage.getItem('seversion');
        if(lastVersion == null){
          // como no existe una version borramos lo que hay y guardamos esta version
          indexedDB.databases().then(dbs => { dbs.forEach(db => { if(db.name!="firebaseLocalStorageDb"){indexedDB.deleteDatabase(db.name)} }) })
          localStorage.setItem('seversion',version )
          console.log("limpio datos");
        }else{
          // comparar si es la version si no pasar
          if (lastVersion != version) {
            indexedDB.databases().then(dbs => { dbs.forEach(db => { if(db.name!="firebaseLocalStorageDb"){indexedDB.deleteDatabase(db.name)} }) })
            localStorage.setItem('seversion',version )
            console.log("limpio datos");
            // // como es diferente actualizamos una vez al dia
            // var lastUpdate = localStorage.getItem('seupdate');
            // if (lastUpdate == null){
            //   indexedDB.databases().then(dbs => {
            //     dbs.forEach(db => indexedDB.deleteDatabase(db.name))
            //   })
            //   var datenow = new Date();
            //   datenow.setHours(0,0,0,0);
            //   localStorage.setItem('seupdate',datenow )
            //   // borrar datos porque es la primera vez que descarga
            //   console.log("borro datos porque es la primera vvez");
            // }else{
            //   var now = new Date();
            //   now.setHours(0,0,0,0);
            //   let memoriaDate=new Date(lastUpdate);
            //   if (now.getTime() > memoriaDate.getTime()) {
            //     indexedDB.databases().then(dbs => {
            //       dbs.forEach(db => indexedDB.deleteDatabase(db.name))
            //     })
            //     var datenow = new Date();
            //     datenow.setHours(0,0,0,0);
            //     localStorage.setItem('seupdate',datenow )
            //     // borrar datos porque no actualizo en el dia
            //     console.log("borro datos porque la actualizacion lo hizo algun dia anterior");
            //   }
            // }

          }
        }
        
             
        // console.log(process.env.VUE_APP_VERSION)
        // let localVersion =process.env.VUE_APP_VERSION;
        // const getVersion = functions.httpsCallable('getVersion')
        // let response =await getVersion()
        // if (response.data != localVersion) {
        //   //este proceso es solo para recargar una vez al dia
        //   var now = new Date();
        //   let memoria = localStorage.getItem('update');
        //   now.setHours(0,0,0,0);
        //   console.log(memoria)
        //   console.log(now)
        //   if (memoria != null) {
        //     let memoriaDate=new Date(memoria);
        //     if (now.getTime() > memoriaDate.getTime()) {
        //       console.log("necesita actualizar")

        //       // indexedDB.databases().then(dbs => {
        //       //   dbs.forEach(db => indexedDB.deleteDatabase(db.name))
        //       // })
        //       var datenow = new Date();
        //       datenow.setHours(0,0,0,0);
        //       localStorage.setItem('update',datenow )
        //       location.reload();
        //     }else{
        //        console.log("recargo el mismo dia")
        //       return;
        //     }
        //   }else{
        //       var datenow = new Date();
        //       datenow.setHours(0,0,0,0);
        //       localStorage.setItem('update',datenow )
        //       location.reload();
        //   }      
        // }
      }
    },
    mounted() {
      this.verifyVersion()
      //return;
      db.collection("TempTokensClever").doc(this.$store.state.user.uid).get().then((doc) => {
        console.log(doc.exists);
        if (!doc.exists) {
          this.$router.push({name: 'studentEdition'});
          return;
        }
        this.token =  doc.data().token;
        //console.log(this.token)
        let ios= this.VerifyIos();
        let searchNavigator = this.searchNavigator();
        //console.log(ios)
        let navigator = window.navigator.userAgent;
        this.OpenAppSe = "leelee://game?token="+this.token;
        db.collection("LogSE").doc(this.$store.state.user.uid).set({ios: ios, url: this.OpenAppSe, navigator: navigator, searchNavigator: searchNavigator}).then(()=>{
          if (ios) {
              window.location.href = "leelee://game?token="+this.token;
              let me = this;
              this.loadButtonRedirect = false;
              setTimeout(function () { me.ShowAlert = true; }, 10000);
          }else{
            console.log('nueva actualizacion')
            let me = this;
            setTimeout(function () { me.$router.push({name: 'studentEdition'}); }, 2000);
            
          }
        });
        
      });
      
    }
}
</script>

<style scoped>
  .fondo-student{
    background-image: url('../../../assets/admin/student-start.png');
    background-position: center;
    background-repeat: no-repeat, repeat-y;
    background-size: cover;
    height: 100vh;
    margin: 0;
    display: flex;
    flex-direction: column;
  }
</style>